
#url, .include-match {
  font-family: monospace;
}

.audit-tabs .tab-content {
  padding: 1rem 0.5rem;
}

.form-group.required .form-label:after {
  content: '*';
  color: red;
}

.code-area {
  font-size: 70%;
  color: #e83e8c;
  word-wrap: break-word;
  font-weight: bold;
}
