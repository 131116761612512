body {
  a {
    color: #005cb7;
  }
  ol.breadcrumb {
    .active {
      color: #53585d;
    }
  }
  .btn-primary {
    background-color: #006BF0;
    border-color: #006BF0;
  }
  .btn-primary:hover {
    background-color: #005BDA;
  }
  .btn-info {
    background-color: #008298;
    border-color: #008298;
  }
  .btn-info:hover {
    background-color: #007087;
  }
}

.code {
  font-family: monospace, monospace;
}

table.expand {
  width: 100% !important;
}

table.data {
  td {
    vertical-align: middle;

    .actions {
      float: right;

      button {
        opacity: 0.7;

        &:hover, &:active {
          opacity: 1;
        }
      }
    }

    &.code {
      white-space: pre-wrap;
      font-size: 90%;
    }
  }

  width: auto;
  background-color: white;
  overflow: hidden;
  margin: 0.5rem 0 1em 0;

  caption {
    font-size: 120%;
    color: #040505;
    caption-side: top;
  }

  > tbody > tr:nth-of-type(2n+1) {
    background-color: #f9f9f9;
  }

  > tbody > tr:hover {
    background-color: #F0FFFA;
  }
}

#root .border {
  padding: 1em;
  border-color: #888 !important;
  border-radius: 0.5rem;
}

.jumbotron#header {
  border-radius: 0;
  min-height: 450px;
}

section {
  margin: 1em 0 1.5em 0;
}

#home-services {
  .fa-stack-1x { color: white; }
  .fa-stack-2x { color: #ff6347; }
}

header {
  .navbar-nav {
    .nav-link {
      color: #d2d2d2 !important;
    }
  }
}

footer {
  a {
    .fa-stack-1x { color: #343a40; }
    .fa-stack-2x { color: white; }

    &:hover, &:focus {
      .fa-stack-1x { color: white; }
      .fa-stack-2x { color: #4d585f; }
    }
  }

  a {
    color: white;

    &:hover, &:focus {
      color: #eee;
    }
  }
}
